/* eslint-disable array-callback-return */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import plusRed from '../../../icons/plusRed.svg';
import './styles.css';
import Addon from './Addon';
import { AddonsProps } from './type';

const Addons: FC<AddonsProps> = ({ addons, addonsFromSettings }) => {
  const { t } = useTranslation();

  return (
    <>
      {addons?.length > 0 ? (
        <div className='addons-container'>
          <Subheader
            isCentered
            icon={plusRed}
            text={t('commons.extras')}
            isBlack
          />
          {addons &&
            addons.map((addon, index) => {
              if (addon.totalPrice)
                return (
                  <Addon
                    id={addon.id}
                    totalPrice={addon.totalPrice}
                    key={addon.id + 'index' + index}
                    title={
                      addonsFromSettings.find((a) => a.id === addon.id)?.title
                    }
                  />
                );
            })}
        </div>
      ) : null}
    </>
  );
};
export default Addons;
