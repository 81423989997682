import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { IStartView } from '../../../interfaces/MainPageRelatedInterfaces';
import MainStartPicture from '../../molecules/MainStartPicture/MainStartPicture';
import mainPicture from '../../../assets/mainPicture5.jpg';
import ToggleSideMenu from '../../molecules/ToggleSideMenu/ToggleSideMenu';
import DownloadApp from '../../molecules/DownloadApp/DownloadApp';
import downloadApp from '../../../assets/downloadApp.png';
import StartDescription from '../../atoms/StartDescription/StartDescription';
import './StartView.css';
import TileContainer from '../../atoms/TileContainer/TileContainer';
import CategoryButtons from './CategoryButtons';
import MostLovedProducts from '../../molecules/MostLovedProducts';
import LastTicket from '../../molecules/LastTicket';

const StartView: FC<IStartView> = () => {
  const { successPageState } = useContext(AppContext);
  const { t } = useTranslation();
  const [showCongratulation, setShowCongratulation] = useState(false);
  const token = localStorage.getItem('token');
  const [isSuccessPageView, setIsSuccessPageView] = successPageState;

  useEffect(() => {
    if (isSuccessPageView === true) {
      setShowCongratulation(true);
      setIsSuccessPageView(false);
      sessionStorage.setItem('isSuccessPageView', 'false');
    }
  }, [isSuccessPageView]);

  return (
    <div className='start-view-container'>
      <div className='leftSectionWeb'>
        <ToggleSideMenu isSuccessPage={showCongratulation} />
        {showCongratulation ? (
          <div>
            <TileContainer>
              <div className='tileWithText' style={{ padding: '1em 0' }}>
                <span className='successPageTitle'>
                  {t('startView.ticketBookedSuccesfully')}!
                </span>
                <p className='tileContainerText'>
                  {t('startView.bookingConfirmation')}
                </p>
              </div>
            </TileContainer>
            {token && (
              <div style={{ marginTop: 15, width: 315 }}>
                <LastTicket />
              </div>
            )}
            <TileContainer>
              <div
                className='tileWithText tileWithSelect'
                style={{ paddingTop: '25px' }}
              >
                <span
                  style={{ marginBottom: '30px', display: 'block' }}
                  className='successPageTitle'
                >
                  {t('startView.bookMoreTickets')}
                </span>
              </div>
            </TileContainer>
          </div>
        ) : (
          <>
            {token && (
              <div style={{ width: 315 }}>
                <LastTicket />
              </div>
            )}
            <StartDescription
              title={t('startView.startDescriptionTitle')}
              text={t('startView.startDescription')}
              link='https://www.laax.com/'
            />
          </>
        )}
      </div>
      <div className='leftSectionMobile'>
        <ToggleSideMenu isSuccessPage={isSuccessPageView} />
        {showCongratulation ? (
          <div>
            <div style={{ marginBottom: 15 }}>
              <TileContainer>
                <div className='tileWithText'>
                  <span className='successPageTitle'>
                    {t('startView.ticketBookedSuccesfully')}!
                  </span>
                  <p className='tileContainerText'>
                    {t('startView.bookingConfirmation')}
                  </p>
                </div>
              </TileContainer>
            </div>
            {token && (
              <div style={{ width: 315 }}>
                <LastTicket />
              </div>
            )}
          </div>
        ) : (
          <>
            {token && (
              <div style={{ width: 315 }}>
                <LastTicket />
              </div>
            )}
          </>
        )}
        <CategoryButtons />
      </div>
      <div className='rightSectionWeb'>
        <div className='rightTop'>
          {showCongratulation ? (
            <div className='successPageHeader'>
              <h1 className='successPageTitleBig'>
                {t('startView.ticketBookedSuccesfully')}!
              </h1>
            </div>
          ) : (
            <MainStartPicture alt='main picture' src={mainPicture} />
          )}
        </div>
        <div className='rightBottom'>
          <MostLovedProducts />
        </div>
      </div>

      <div className='leftSectionMobile'>
        {showCongratulation ? null : (
          <StartDescription
            title={t('startView.startDescriptionTitle')}
            text={t('startView.startDescription')}
            link='https://www.flimslaax.com/'
          />
        )}
        <div className='leftSectionMobile'>
          <DownloadApp alt='download app picture' src={downloadApp} />
        </div>
      </div>
    </div>
  );
};

export default StartView;
