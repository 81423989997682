import { FC, useContext } from 'react';
import { AppContext } from '../../../../../AppContext';
import sendAnalytics from '../../../../../utils/sendAnalytics';

const AmountButton: FC<{ id: string; amount: number; price?: number }> = ({
  id,
  amount,
  price,
}) => {
  const { chosenAddonsState, availableProductsState, chosenSubcategoryState } =
    useContext(AppContext);

  const [chosenAddons, setChosenAddons] = chosenAddonsState;
  const [availableProducts] = availableProductsState;
  const [chosenSubcategory] = chosenSubcategoryState;

  const product = availableProducts.find(
    (aP: any) => aP.id === chosenSubcategory
  );

  const productAnalytics = {
    currency: 'chf',
    product_id: product?.id,
    sku: product?.id + '--' + id,
    category: product?.category,
    name: product?.title,
    variant: id,
    price: price && price / 100,
    quantity: 1,
  };
  const handleOnPlusClick = (): void => {
    sendAnalytics('Product Added', productAnalytics);
    const newAddons = [...chosenAddons, id];
    setChosenAddons(newAddons);
    sessionStorage.setItem('chosenAddons', String(newAddons));
  };
  const handleOnMinusClick = (): void => {
    sendAnalytics('Product Removed', productAnalytics);
    const elementIndex = chosenAddons?.indexOf(id);
    if (elementIndex !== -1) {
      const newAddons = chosenAddons?.filter(
        (e: string, index: number) => elementIndex !== index
      );
      setChosenAddons(newAddons);
      sessionStorage.setItem('chosenAddons', String(newAddons));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <span
            className='amount-control'
            style={{ paddingRight: '4px' }}
            onClick={handleOnMinusClick}
          >
            {'-'}
          </span>
        </div>
        <span className={'amount'} style={{ opacity: amount % 2 ? 1 : 0.99 }}>
          {amount < 10 ? `${0}${amount}` : amount}
        </span>
        <div>
          <span className='amount-control' onClick={handleOnPlusClick}>
            {'+'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AmountButton;
