import apisauce from 'apisauce';
import moment from 'moment';

type GeneratePassProps = {
  title: string;
  description: string;
  qrCode: string;
  startDate: string;
  endDate?: string;
  walletID: string;
};
const token = localStorage.getItem('token');
const api = apisauce.create({
  baseURL: 'https://wallets.innokrea.pl/',
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
  },
  timeout: 30 * 1000,
});

export default async (data: GeneratePassProps) => {
  const response = await api.post('generateWalletPass/', data, {
    responseType: 'arraybuffer',
  });

  if (response.ok && response.data) {
    const blob = new Blob([response.data as ArrayBuffer], {
      type: 'application/vnd.apple.pkpass',
    });
    const elem = document.createElement('a');
    elem.href = URL.createObjectURL(blob);
    elem.download = `wallet-pass-${
      data.startDate || moment().format('DD.MM.YYYY')
    }.pkpass`;
    elem.click();
    URL.revokeObjectURL(elem.href);
  }
};
