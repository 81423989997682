import { FC, useContext } from 'react';
import { UpgradeProps } from './type';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import { AppContext } from '../../../../AppContext';
import sendAnalytics from '../../../../utils/sendAnalytics';
import priceDivided from '../../../../utils/priceDivided';

const Upgrade: FC<UpgradeProps> = ({ id, totalPrice, summaryPrice, title }) => {
  const {
    chosenUpgradesState,
    availableProductsState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const [chosenUpgrades, setChosenUpgrades] = chosenUpgradesState;
  const [availableProducts] = availableProductsState;
  const [chosenSubcategory] = chosenSubcategoryState;

  const product = availableProducts.find(
    (aP: any) => aP.id === chosenSubcategory
  );

  const amount = chosenUpgrades?.filter((cA: string) => cA === id).length;
  const upgradeSelected = chosenUpgrades[0] === id;

  const handleOnCircleClick = (): void => {
    const newUpgrades =
      chosenUpgrades[0] && chosenUpgrades[0] === id ? [] : [id];
    setChosenUpgrades(newUpgrades);
    sessionStorage.setItem('chosenUpgrades', String(newUpgrades));
    const title = newUpgrades.length ? 'Product Added' : 'Product Removed';
    sendAnalytics(title, {
      currency: 'CHF',
      product_id: product?.id,
      sku: product?.id + '--' + id,
      category: product?.category,
      name: product?.title,
      variant: id,
      price: priceDivided(summaryPrice),
      quantity: 1,
    });
  };

  return (
    <div
      style={{
        borderBottom: '1px solid #f0f0f0',
        padding: '10px 0',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h3 style={{ margin: 0, width: '220px' }}>{title || id}</h3>
        <div className={'upgrades-circle'} onClick={handleOnCircleClick} />
        <div
          className={'upgrades-dot'}
          style={{
            right: upgradeSelected ? 6 : 16,
            background: upgradeSelected ? '#af272f' : 'grey',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <p className='small-grey-text'>
            CHF {summaryPrice ? formatPriceForDisplay(summaryPrice) : '-'}
          </p>
        </div>
        <span className='amount-counter-total-price'>
          CHF {totalPrice ? formatPriceForDisplay(totalPrice * amount) : '-'}
        </span>
      </div>
    </div>
  );
};

export default Upgrade;
