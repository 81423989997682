import { gql } from '@apollo/client';

export const FETCH_PRODUCT_TITLE_BY_PRODUCT_ID = gql`
  query GetProductTitleByProductID($productId: String!) {
    shopLoadKeycardTicketProduct(productId: $productId) {
      title
      description
      quantityConfig
      addons {
        id
        title
      }
      upgrades {
        id
        title
      }
    }
  }
`;

export const FETCH_PRODUCT_SETTINGS = gql`
  query GetProductConfigIDByProductID($productId: String!) {
    shopLoadKeycardTicketProduct(productId: $productId) {
      configId
      title
      description
      personTypes {
        id
        title
      }
      availability {
        maxConsecutiveDays
      }
      addons {
        id
        title
      }
      upgrades {
        id
        title
      }
      options {
        title
        values {
          id
          title
        }
      }
    }
  }
`;
